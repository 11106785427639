<template>
  <div class="flex justify-between w-full">
    <div class="payment-method--type flex items-center">
      <Checkbox
        class="radio mr-4"
        v-model="localValue"
        v-if="!hideRadio"
        :deselectedValue="0"
        :selected-value="method.virtual_account_id"
      />
      <font-awesome-icon class="ml-4" :icon="['fab', creditCardIcon()]" />
      <div class="flex flex-col ml-4 justify-center payment-method--card">
        <span class="mb-1">**** **** {{ method.card.last4 }}</span>
        <small> expires {{ method.card.exp_month }}/{{ method.card.exp_year }} </small>
      </div>
    </div>
    <Btn
      @click="() => $emit('onDelete', method.virtual_account_id)"
      v-if="!hideDelete"
      class="bg-white"
    >
      <font-awesome-icon icon="trash" />
    </Btn>
  </div>
</template>
<script>
export default {
  name: 'CreditCardPaymentMethod',
  props: {
    method: {
      type: Object,
      required: true
    },
    value: {
      type: Number
    },
    hideRadio: {
      type: Boolean,
      default: false
    },
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onDelete', 'input'],
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
    },
    value() {
      if (this.value !== this.localValue) {
        this.localValue = this.value
      }
    }
  },
  methods: {
    /**
     * Lookup the credit card icon to display
     */
    creditCardIcon() {
      if (this.method.card && this.method.card.brand) {
        if (this.method.card.brand === 'visa') {
          return 'cc-visa'
        }
        if (this.method.card.brand === 'mastercard') {
          return 'cc-mastercard'
        }
      }
      return 'credit-card'
    }
  }
}
</script>
