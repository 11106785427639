<template>
  <div class="payment-contents">
    <div
      v-show="!loadingPaymentElements && paymentsSuccessfullyInitialized"
      class="flex flex-col payment-method--ctn"
    >
      <!-- Payment contents header section -->
      <div
        v-show="!loadingPaymentElements && paymentsSuccessfullyInitialized"
        class="payment-method--create mb-6 flex justify-between"
      >
        <h5>{{ $t('Secure payment methods') }}</h5>
        <!-- Manual payment button -->
        <div
          v-if="manualPaymentObject && selectedPaymentType !== 'manual'"
          @click="selectMethod(manualPaymentObject.value)"
        >
          <a class="text-info text-center">
            {{ $t(manualPaymentObject.text) }}
          </a>
        </div>
      </div>

      <!-- Payment method selection section -->
      <div
        class="secure-payment-methods--ctn"
        v-show="!loadingPaymentElements && paymentsSuccessfullyInitialized"
      >
        <!-- Payment method cards -->
        <div
          class="secure-payment-methods flex flex-col md:flex-row items-center md:gap-x-8 justify-center w-full mb-5"
        >
          <div
            v-for="method in visibleCustomPaymentMethods"
            class="mb-3 mb-md-0"
            :key="method.value"
          >
            <PaymentMethodCard
              class="payment-method-card"
              :selected="selectedPaymentType"
              @select-method="selectMethod(method.value)"
              :invoiceId="invoice.invoice_id"
              :method="method"
              :fee="allFees[method.value]"
              :token="token"
              :enabled="method.enabled"
            >
            </PaymentMethodCard>
          </div>
        </div>
      </div>

      <!-- STRIPE - Payment card / account selection section -->

      <StripeStoredPaymentMethods
        ref="stripe-stored-payment-methods"
        class="mb-4"
        v-if="payFacService === 'stripe'"
        v-show="!newAccount"
        :storedPaymentMethods="storedPaymentMethods"
        :selectedPaymentType="selectedPaymentType"
        :token="token"
        :onSuccess="onSelectNewAccount"
        @select-stored-payment-method="selectStoredPaymentMethod"
      />

      <div
        v-if="payFacService === 'adyen'"
        class="payment-method--action"
        v-show="selectedPaymentType === 'card'"
      >
        <div class="p-4" id="adyen-credit-card-payment-element"></div>
      </div>
      <div
        v-if="payFacService === 'adyen'"
        class="payment-method--action mb-3"
        v-show="isBankTransfer"
      >
        <Card
          :flat="true"
          :class="[
            'checkout_payment-method mb-2',
            selectedBankAccountId === account.id ? 'active' : ''
          ]"
          v-for="account in adyenPMResponse.ach.storedPaymentMethods"
          :key="account.id"
        >
          <div class="checkout_payment-method--content">
            <Checkbox
              class="radio"
              v-model="selectedBankAccountId"
              @change="onSelectedBankAccount"
              :selected-value="account.id"
            />
            <font-awesome-icon class="text-x ml-3" icon="building-columns" />
            <span class="ml-2 flex flex-col">
              <span>XXXX {{ account.bankAccountNumber.slice(-4) }}</span>
              <small>{{ account.name }}</small>
            </span>
          </div>
        </Card>
      </div>

      <PlaidLink
        v-if="payFacService === 'adyen' && isBankTransfer"
        :token="token"
        :tigger="addingNewBankAccount === true"
        :asLink="true"
        @on-success="onSelectNewAccount"
        @on-open="
          () => {
            addingNewBankAccount = true
            selectedBankAccountId = 0
          }
        "
        @on-exit="() => (addingNewBankAccount = false)"
        btn-class="flex flex-row items-center"
        ref="plaid"
      >
        <Card
          :class="['checkout_payment-method', addingNewBankAccount ? 'active' : '']"
          :flat="true"
        >
          <div>
            <div class="checkout_payment-method--content">
              <Checkbox
                class="radio"
                v-model="addingNewBankAccount"
                :selected-value="true"
                :deselected-value="false"
              />
              <font-awesome-icon class="ml-3" icon="university" />
              <p class="ml-2">
                {{ $t('Add new bank account') }}
              </p>
            </div>
            <div class="mt-4 p-4" v-show="isBankTransfer && newAccount">
              <div v-if="paymentDataValid && newAccount" class="bank-account-method">
                <BankAccountPaymentMethod
                  :hideRadio="true"
                  :hideDelete="true"
                  :hideDefault="true"
                  :hideIcon="true"
                  :method="newAccount"
                />
              </div>
              <small v-if="paymentDataValid && newAccount" class="mt-4 block">
                {{
                  $t(
                    'By pressing the payment button, you authorize Bolster to debit the specified bank account for the amount owed for charges arising from the use of services and/or purchase of products.'
                  )
                }}
              </small>
            </div>
          </div>
        </Card>
      </PlaidLink>

      <div class="selected-secure-method h-auto mt-2 mb-2">
        <Card class="mt-4" v-show="isBankTransfer && newAccount && payFacService === 'stripe'">
          <div v-if="paymentDataValid && newAccount" class="bank-account-method">
            <BankAccountPaymentMethod
              :hideRadio="true"
              :hideDelete="true"
              :hideDefault="true"
              :hideIcon="true"
              :method="newAccount"
            />
          </div>
          <small v-if="paymentDataValid && newAccount" class="mt-4 block">
            {{
              $t(
                'By pressing the payment button, you authorize Bolster to debit the specified bank account for the amount owed for charges arising from the use of services and/or purchase of products.'
              )
            }}
          </small>
        </Card>
        <!-- Manual Payment Method Details -->
        <Card v-if="selectedPaymentType === 'manual'">
          <b class="flex w-full justify-center">{{ $t('Requested payment method') }}</b>
          <div class="flex justify-center w-full mt-4">
            <selection-toggle
              :value="manualPaymentMethod"
              v-model="manualPaymentMethod"
              :options="manualPaymentOptions"
            />
          </div>
          <Field
            class="w-full mt-4"
            v-model="paymentMethodDescriptor"
            placeholder="Name of requested payment method..."
            v-if="manualPaymentMethod === 'Other'"
          />
        </Card>
      </div>

      <!-- Pay Invoice Button -->
      <Btn
        v-if="
          selectedPaymentType !== 'manual' &&
          !loadingPaymentElements &&
          paymentsSuccessfullyInitialized
        "
        class="mt-4"
        :disabled="!validated || loading === 1"
        :loading="loading"
        size="xl"
        :action="handlePayInvoice"
        severity="primary-black"
      >
        {{ $t('Pay Invoice ') }} <em class="ml-2">{{ $f.currency(amount) }}</em>
      </Btn>

      <Btn
        class="round info mt-4"
        :disabled="
          !manualPaymentMethod ||
          (manualPaymentMethod === 'Other' &&
            (!paymentMethodDescriptor || paymentMethodDescriptor === ''))
        "
        :loading="loading"
        size="xl"
        v-if="selectedPaymentType === 'manual'"
        :action="manualPayInvoice"
        severity="primary-black"
      >
        {{ $t('Request manual payment') }}
      </Btn>

      <PaymentRequestBtn
        v-if="allowedPaymentMethods.includes('wallet')"
        :amount="walletAmount"
        :name="invoice.invoice_name"
        @payment-method-added="onPaymentMethodAdded"
        class="mt-4"
      />
    </div>
    <div v-if="loadingPaymentElements" class="w-full text-center">
      <Spinner :loading="1" />
    </div>

    <div id="3d-verification-newTab"></div>

    <mini-modal
      ref="3d-verification-modal"
      classes="white verification-modal"
      :closeable="false"
      :clickAway="false"
      size="md"
    >
      <h3 class="mb-2">
        {{ $t('3D Security Verification') }}
      </h3>
      <p class="mb-4">
        {{
          $t(
            'This card requires 3D secure verification. Please complete the authorization below. Once you are complete click the close button.'
          )
        }}
      </p>
      <div
        class="w-full verification-modal--ctn"
        v-if="!verificationError && !verificationSuccess"
        id="3d-verification"
      ></div>
      <div v-if="verificationSuccess" class="flex text-center w-full justify-center flex-col py-4">
        <h4>Success</h4>
        <p>Payment 3D secure authorization was successful</p>
        <Checkmark class="success my-2 mx-auto" :size="100" />
      </div>
      <danger :hideTitle="true" class="mt-4" v-if="verificationError">
        {{ verificationError }}
      </danger>
      <btn class="btn info mt-4" @click="onConfirm3DVerification"> Close </btn>
    </mini-modal>
    <danger :hideTitle="true" class="mt-4" v-if="error">
      {{ error }}
    </danger>
  </div>
</template>

<script>
import PayfacMixin from './PayfacMixin'
import { paymentMethods } from './paymentMethods/PaymentMethods'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'

import BankAccountPaymentMethod from './paymentMethods/BankAccountPaymentMethod.vue'
import PaymentRequestBtn from './stripe/PaymentRequestBtn.vue'
import PaymentMethodCard from './paymentMethods/PaymentMethodCard.vue'
import StripeStoredPaymentMethods from './stripe/StripeStoredPaymentMethods.vue'
import PlaidLink from './plaid/PlaidLink.vue'

export default {
  name: 'PaymentContents',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  emits: ['manualPay', 'amountUpdated'],
  data() {
    return {
      // Our custom methods, we use plaid for transfer
      loading: 0,
      error: null,
      verificationError: null,
      verificationSuccess: false,
      allFees: {},
      specialtyCards: [],
      variantFees: [],
      // stored payment methods
      storedPaymentMethods: null,
      selectedStoredPaymentMethodId: '',
      selectedStoredPaymentMethod: null,
      // new payment method
      paymentData: null,
      isPaymentDataValid: false,
      // payment type
      selectedPaymentType: '',

      // allowed payment methods
      allowedPaymentMethods: ['card', 'manual'],

      newAccount: null,
      loadingPaymentElements: true,
      token: null,
      manualPaymentMethod: null,
      paymentMethodDescriptor: null,
      manualPaymentOptions: [
        {
          text: 'Cash',
          value: 'Cash'
        },
        {
          text: 'Check',
          value: 'Check'
        },
        {
          text: 'Bank Draft',
          value: 'Bank Draft'
        },
        {
          text: 'Other',
          value: 'Other'
        }
      ],
      addingNewBankAccount: false,
      selectedBankAccountId: 0
    }
  },
  mixins: [PayfacMixin, TranslationMixin],
  components: {
    StripeStoredPaymentMethods,
    PlaidLink,
    BankAccountPaymentMethod,
    PaymentRequestBtn,
    PaymentMethodCard
  },
  computed: {
    isBankTransfer() {
      return this.selectedPaymentType === 'ach' || this.selectedPaymentType === 'eft'
    },
    paymentsSuccessfullyInitialized() {
      if (Object.values(this.allFees) && Object.values(this.allFees).length) return true
      return false
    },
    amount() {
      if (this.selectedPaymentType === 'card') {
        return this.invoice.invoice_gross + this.allFees.card
      }
      if (this.isBankTransfer) {
        return this.invoice.invoice_gross + this.allFees.ach
      }
      return this.invoice.invoice_gross
    },
    walletAmount() {
      return this.invoice.invoice_gross + this.allFees.card
    },
    /**
     * Check to see if a payment method has been set
     */
    storedPaymentMethodValid() {
      return (
        this.selectedStoredPaymentMethodId &&
        this.selectedStoredPaymentMethod &&
        Object.keys(this.selectedStoredPaymentMethod).length > 0
      )
    },
    /**
     * Check to see if payment data has been populated
     */
    paymentDataValid() {
      return this.paymentData && Object.keys(this.paymentData).length > 0 && this.isPaymentDataValid
    },
    /**
     * Check to see if data is valid or selected payment method is valid
     */
    validated() {
      return this.isPaymentDataValid || this.storedPaymentMethodValid
    },
    customPaymentMethods() {
      return paymentMethods
        .map((p) => {
          // If the type is one of the allowed payment methods,
          // enable and return it, otherwise leave it disabled
          if (this.allowedPaymentMethods.includes(p.value)) {
            return {
              ...p,
              enabled: true
            }
          }

          return p
        })
        .filter((p) => p.enabled)
    },
    visibleCustomPaymentMethods() {
      return this.customPaymentMethods.filter((method) => method.value !== 'manual')
    },
    manualPaymentObject() {
      const methodList = this.customPaymentMethods.filter((p) => p.value === 'manual')

      return methodList.length ? methodList[0] : null
    }
  },
  methods: {
    async onConfirm3DVerification() {
      await this.$store.dispatch('ajax', {
        path: `invoice/verifyAsPaid/${this.invoice.invoice_id}`
      })
      this.$refs['3d-verification-modal'].close()
      window.location.reload()
    },
    onSelectedBankAccount() {
      const bankAccount = this.adyenPMResponse.ach.storedPaymentMethods.find(
        (f) => f.id === this.selectedBankAccountId
      )
      this.selectedStoredPaymentMethodId = this.selectedBankAccountId
      this.selectedStoredPaymentMethod = bankAccount
      this.paymentData = {
        paymentMethod: {
          type: bankAccount.type,
          storedPaymentMethodId: bankAccount.id
        }
      }
      this.isPaymentDataValid = true
      this.newAccount = null
      this.addingNewBankAccount = false
    },
    /**
     * Manual payment method handle
     */
    async manualPayInvoice() {
      this.loading = true
      await this.payManually({
        invoice_id: this.invoice.invoice_id,
        payment_method: this.manualPaymentMethod,
        payment_method_descriptor: this.paymentMethodDescriptor
      })
      this.$emit('manualPay')
    },
    /**
     * After the payment request button has been activated
     * and a payment method selected
     */
    onPaymentMethodAdded(paymentMethod) {
      const { id, type } = paymentMethod
      if (id) this.paymentDataValid = true

      this.paymentData = {
        source_type: type,
        payment_method_id: id
      }
      this.handlePayInvoice()
    },
    /**
     * After you've added a bank account you can remove it and add another
     */
    removeNewBank() {
      this.paymentData = null
      this.newAccount = null
      this.addingNewBankAccount = false
      if (this.payFacService === 'stripe') this.$refs['stripe-stored-payment-methods'].reset()
    },
    /**
     * Handle the pay button click
     */
    async handlePayInvoice() {
      if (!this.validated || this.loading) return
      try {
        this.error = null
        await this.payInvoice()
      } catch (e) {
        this.error = typeof e === 'string' ? e : e.message || 'Could not process payment method.'
        this.$store.dispatch('alert', {
          message: this.error,
          error: true
        })
        this.resetData()
        this.selectedPaymentType = null
      }
    },
    selectStoredPaymentMethod(storedMethodId) {
      this.selectedStoredPaymentMethodId = storedMethodId
      if (storedMethodId) {
        const selected = this.storedPaymentMethods.filter(
          (method) => method.virtual_account_id === storedMethodId
        )
        if (selected && selected.length) this.selectedStoredPaymentMethod = selected[0]
      } else {
        this.selectedStoredPaymentMethod = null
      }
    },

    /**
     * Select a payment method via radio button
     */
    selectMethod(value) {
      this.resetData()

      // stripe payment element
      if (this.paymentElement) this.paymentElement.clear()
      let selectedType = value

      if (selectedType === 'scheme') selectedType = 'card'

      if (selectedType === 'manual') {
        this.$store.dispatch('modal/confirm', {
          message: 'Are you sure you want to make a payment externally?',
          body: '',
          actions: {
            confirm: {
              title: 'Confirm',
              action: () => {
                this.selectedPaymentType = selectedType
                this.$emit('amountUpdated', 0)
              },
              setDone: false,
              class: ''
            },
            cancel: {
              title: 'Cancel',
              class: 'info'
            }
          }
        })
        return
      }

      this.selectedPaymentType = selectedType
      console.log(this.selectedPaymentType, 'selectedPaymentType')
      this.$emit('amountUpdated', this.allFees[selectedType] || 0)
    },

    resetData() {
      this.isPaymentDataValid = false
      this.paymentData = null
      this.newAccount = null
      this.addingNewBankAccount = false
      this.selectedBankAccountId = 0
      this.selectedStoredPaymentMethodId = ''
      this.selectedStoredPaymentMethod = null
      if (this.payFacService === 'stripe') this.$refs['stripe-stored-payment-methods'].reset()
    },

    /**
     * When selecting to create a new bank account
     */
    onSelectNewAccount({ publicToken, metadata }) {
      const { account } = metadata

      if (this.payFacService === 'stripe') {
        this.paymentData = {
          source_type: 'ach',
          plaid: {
            public_token: publicToken,
            account
          }
        }
      }

      if (this.payFacService === 'adyen') {
        this.paymentData = {
          paymentMethod: {
            type: this.selectedPaymentType,
            public_token: publicToken,
            account
          }
        }
      }

      this.isPaymentDataValid = true

      this.newAccount = {
        type: 'bank_account',
        bank_name: account.name,
        last4: account.mask
      }
    },
    /**
     * After credit card has been filled out and the
     * payment intent confirmed store it to be passed to api.
     */
    paymentIntentCreated(paymentIntent) {
      this.paymentData = {
        payment_intent_id: paymentIntent.id,
        source_type: 'card',
        pay_invoice: true,
        invoice_id: this.invoice.invoice_id
      }
    }
  },
  async mounted() {
    this.loadingPaymentElements = true
    await this.initializePayments()
    this.loadingPaymentElements = false
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.adyen-checkout__button {
  background-color: #1f92fc;
}

.plaid-link {
  text-align: center;
}

.payment-method--new.card.card--container .card-body {
  padding: 0px !important;
}

.payment-method--custom {
  width: 100%;

  div:first-of-type {
    padding-left: 0 !important;
    .nav-link {
      border-radius: 1em 0 0 0;
    }
  }

  .nav-item {
    padding: 0px !important;
  }

  .nav-link {
    font-size: 1.3rem;
    padding: 1rem 2rem;
  }

  .card.card--container {
    padding: 0.8rem;
  }

  .payment-method--content {
    display: flex;
    flex-wrap: nowrap;
  }
}

.payment-methods--actions {
  padding: 2rem;
  width: 100%;
  .payment-method--action {
    width: 100%;
  }
}

.card.card--container
  .card-body
  .content
  .card-text
  > *:not(:first-child).payment-methods--actions {
  margin-top: 0 !important;
}

.payment-method--type {
  width: 100%;
  .payment-method--card {
    height: 100%;
  }
}

.nav-link {
  background-color: $cool-gray-300 !important;
}

.nav-link.active {
  background-color: $flame-white !important;
  border-bottom-color: $flame-white !important;
}

.bank-account-method {
  position: relative;
  width: 100%;
}

.remove-bank {
  position: absolute !important;
  right: 0;
  top: 0;
}

#stripe-elements-payment {
  iframe {
    height: 30px !important;
  }
}

.checkout_payment-method {
  &:not(.active) {
    background-color: $flame-white;
  }
  width: 100%;
  .checkout_payment-method--content {
    display: flex;
    align-items: center;
    width: 100%;
    [data-icon] {
      margin-top: -5px;
    }
  }
}

#adyen-ach-payment-element {
  background-color: $flame-white;
}

.payment-contents {
  .fa-layers {
    position: relative;
    .fa-layers-icon {
      position: absolute;
      bottom: 0;
      right: -0.2em;
      top: -0.35em;
    }
  }
}

.verification-modal--ctn {
  width: 600px;
  height: 400px;
  display: flex;
  justify-content: center;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
