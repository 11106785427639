<template>
  <CardList>
    <template v-if="selectedPaymentType && sortedPaymentMethods[selectedPaymentType].length > 0">
      <CardListItem
        v-for="method in sortedPaymentMethods[selectedPaymentType]"
        :key="method.virtual_account_id"
      >
        <template v-if="method.virtual_account_type === selectedPaymentType">
          <BankAccountPaymentMethod
            :method="method"
            :hideDefault="true"
            :hideDelete="true"
            @on-delete="(id) => deletePaymentMethod(id)"
            v-model="selectedStoredPaymentMethodId"
            v-if="method.virtual_account_type === 'ach'"
          />
          <CreditCardPaymentMethod
            :method="method"
            :hideDelete="true"
            @on-delete="(id) => deletePaymentMethod(id)"
            v-model="selectedStoredPaymentMethodId"
            v-if="method.type === 'card'"
          />
        </template>
      </CardListItem>
    </template>

    <!-- Add new bank button -->
    <CardListItem
      v-if="selectedPaymentType === 'ach'"
      key="new2"
      class="flex flex-col items-start w-full"
    >
      <div class="flex flex-row items-center new-account--ctn w-full">
        <PlaidLink
          :token="token"
          :asLink="true"
          @on-success="onSuccess"
          @on-exit="onPlaidExit"
          btn-class="flex flex-row items-center"
          :action="addedNewBank"
          ref="plaid"
        >
          <Checkbox
            class="radio"
            v-model="addingNewBank"
            :selected-value="true"
            :deselected-value="false"
          />
          <font-awesome-icon class="ml-4" icon="university" />
          <span class="ml-4">Add new bank account</span>
        </PlaidLink>
      </div>
    </CardListItem>

    <!-- Add new credit card button -->
    <CardListItem
      v-if="selectedPaymentType === 'card'"
      key="new"
      class="flex flex-col items-start w-full"
    >
      <div class="flex flex-row items-center w-full">
        <Checkbox
          class="radio mr-4"
          v-model="addingNewCreditCard"
          :selected-value="true"
          :deselected-value="false"
        />
        <font-awesome-icon icon="credit-card" class="ml-4" />
        <span class="ml-4">Add new credit card</span>
      </div>
    </CardListItem>

    <!-- credit card component -->
    <div class="payment-method--action" v-show="showNewCreditCard">
      <div class="p-4" id="stripe-credit-card-payment-element"></div>
    </div>
  </CardList>
</template>

<script>
import CreditCardPaymentMethod from '../paymentMethods/CreditCardPaymentMethod.vue'
import BankAccountPaymentMethod from '../paymentMethods/BankAccountPaymentMethod.vue'
import PlaidLink from '../plaid/PlaidLink.vue'

export default {
  name: 'StripeStoredPaymentMethods',
  components: {
    CreditCardPaymentMethod,
    BankAccountPaymentMethod,
    PlaidLink
  },
  props: {
    storedPaymentMethods: {
      required: true
    },
    selectedPaymentType: {
      required: true
    },
    token: {
      required: false
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },
  emits: ['selectStoredPaymentMethod'],
  data() {
    return {
      selectedStoredPaymentMethodId: null,
      addingNewCreditCard: false,
      addingNewBank: false
    }
  },
  watch: {
    selectedStoredPaymentMethodId(value) {
      if (value) {
        this.addingNewCreditCard = false
        this.addingNewBank = false
      }
      this.$emit('selectStoredPaymentMethod', value)
    },
    addingNewCreditCard(value) {
      if (value) this.selectedStoredPaymentMethodId = 0
    },
    addingNewBank(value) {
      if (value) {
        this.selectedStoredPaymentMethodId = 0
        this.$refs.plaid.handleOnClick()
      }
    }
  },
  computed: {
    showNewCreditCard() {
      if (this.selectedPaymentType !== 'card') return false
      if (!this.hasStoredCreditCardPM) return true
      return this.addingNewCreditCard
    },
    hasStoredCreditCardPM() {
      const creditCardPM = this.storedPaymentMethods.filter(
        (method) => method.virtual_account_type === 'card'
      )
      return Boolean(creditCardPM.length)
    },
    storedCards() {
      return this.storedPaymentMethods.filter((p) => p.type === 'card')
    },
    storedAccounts() {
      return this.storedPaymentMethods.filter((p) => p.virtual_account_type === 'ach')
    },
    sortedPaymentMethods() {
      return {
        ach: this.storedAccounts || [],
        card: this.storedCards || []
      }
    }
  },
  mounted() {
    console.log('storedPaymentMethods', this.storedPaymentMethods)
  },
  methods: {
    reset() {
      this.selectedStoredPaymentMethodId = 0
      this.addingNewBank = false
      this.addingNewCreditCard = false
    },
    addedNewBank() {
      this.addingNewBank = true
    },
    onPlaidExit() {
      this.addingNewBank = false
    },
    /**
     * Delete a payment method
     * @param {string} virtualAccountId
     */
    deletePaymentMethod(virtualAccountId) {
      // make sure user confirms first
      this.$store.dispatch('modal/confirm', {
        message: 'Are you sure you want to delete this payment method?',
        actions: {
          confirm: {
            title: 'Yes, delete',
            action: async () => {
              try {
                // pass the virtual account id
                const res = await this.$store.dispatch('ajax', {
                  path: 'gateway/deletePaymentMethod',
                  data: {
                    virtual_account_id: virtualAccountId
                  }
                })

                if (!res) {
                  throw new Error('Could not remove payment method')
                }
                // response returns remaining payment methods
                const { payload } = res
                this.paymentMethods = this.formatPaymentMethods(payload)
              } catch (e) {
                this.$store.dispatch('alert', {
                  message: e.message || 'Could not remove payment method',
                  error: true
                })
              }
            }
          }
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss"></style>
