<template>
  <scroll-container>
    <container size="5xl">
      <invoice-presentation-component
        ref="body"
        v-if="!waiting && id"
        :trackChanges="true"
        :id="id"
        @selected="selectedHandler"
        @changes="(ch) => (changes = ch)"
        @is-dirty="(b) => (isDirty = b)"
      />
    </container>
  </scroll-container>
</template>

<script>
import InvoicePresentationComponent from '../invoice/presentation/InvoicePresentation.vue'
import PageMixin from '../mixins/Page'
import PublicGatewayPage from '../mixins/PublicGatewayPage'

export default {
  mixins: [PageMixin, PublicGatewayPage],
  data() {
    return {
      isDirty: 0,
      changes: null,
      invoiceSelected: null,
      waiting: 1,
      loading: 1
    }
  },
  computed: {
    isInvoiceLoading() {
      return this.invoiceSelected === null ? 1 : 0
    },
    id() {
      return this.$route.params.id || this.$store.state.session.scope.invoice
    }
  },
  methods: {
    selectedHandler() {
      if (this.$refs.body && this.$refs.body.selected) {
        this.invoiceSelected = this.$refs.body && this.$refs.body.cast()
        c.throttle(() => {}, { delay: 7000 })
      }
    }
  },
  mounted() {
    this.loading = 1
    setTimeout(() => {
      this.waiting = 0
      this.loading = 0
    }, 700)
  },

  components: { InvoicePresentationComponent }
}
</script>

<style>
.pres-page-container .scroll-container--inner {
  margin-bottom: 0 !important;
}
</style>
