<template>
  <card-section>
    <InvoicePresentationSuccess
      class="mb-4"
      :status="invoice_status"
      :payNow="onPayNow"
      :loading="isReversingManualPay"
      v-show="invoice_status === 'p' || invoice_status === '$'"
    />

    <InvoicePresentationAmounts
      v-bind="$props"
      :reference="refId"
      :invoiceSettings="oSettings"
      :hideWarning="true"
      :hidePercentageOfAmount="true"
      :store="storeName"
      :type="type"
      v-model="subComponentInterface"
    />

    <card-section v-if="invoice_desc">
      <card>
        <p class="quote-pres--heading-description">
          {{
            invoice_desc.includes('is the invoice description')
              ? $t('default invoice description')
              : invoice_desc
          }}
        </p>
      </card>
    </card-section>

    <InvoicePresentationTotals
      v-bind="$props"
      :quote="quote"
      :invoiceSettings="oSettings"
      :reference="refId"
      :store="storeName"
      :type="type"
      v-model="subComponentInterface"
      :selectedItems="invoiceItems"
    />

    <card-section v-if="isPaymentsOnboarded" class="mb-4 mt-4">
      <PaymentContents
        v-if="invoice && invoice_status === 'o'"
        @paid="onPaid"
        @amount-updated="amountUpdated"
        @manual-pay="onManualPay"
        :invoice="invoice"
      />
    </card-section>

    <card-section v-if="invoice_status !== 'p' && invoice_status !== 'o'" class="mb-4 mt-4">
      <InvoicePresentationStatus
        :status="invoice_status"
        :paidOn="invoice_time_paid"
        :payNow="onPayNow"
        :loading="isReversingManualPay"
      />
    </card-section>

    <InvoicePresentationPrompt v-if="!isPaymentsOnboarded" :invoiceId="invoice_id" />

    <InvoicePresentationDownload
      @download="downloadInvoice"
      :invoiceStatus="invoice_status"
      :counterpartyStatus="counterpartyStatus"
      :isPaymentsOnboarded="isPaymentsOnboarded"
    />
  </card-section>
</template>

<script>
import ObjectDistinct from '../../mixins/ObjectDistinct'
import TranslationMixin from './languages/TranslationMixin'
import InvoicePDFMixin from '../InvoicePDFMixin'

import PaymentContents from '../../payments/PaymentContents.vue'
import InvoicePresentationTotals from './InvoicePresentationTotals.vue'
import InvoicePresentationAmounts from './InvoicePresentationAmounts.vue'
import InvoicePresentationDownload from './InvoicePresentationDownload.vue'
import InvoicePresentationStatus from './InvoicePresentationStatus.vue'
import InvoicePresentationSuccess from './InvoicePresentationSuccess.vue'
import InvoicePresentationPrompt from './InvoicePaymentsPrompt.vue'
import ItemizedMixin from '../itemized/ItemizedMixin'
import PayfacMixin from '../../payments/PayfacMixin'

export default {
  name: 'PresentationContents',
  components: {
    PaymentContents,
    InvoicePresentationTotals,
    InvoicePresentationAmounts,
    InvoicePresentationDownload,
    InvoicePresentationStatus,
    InvoicePresentationSuccess,
    InvoicePresentationPrompt
  },
  mixins: [
    ObjectDistinct('invoice'),
    TranslationMixin,
    InvoicePDFMixin,
    PayfacMixin,
    ItemizedMixin
  ],
  data() {
    return {
      invoice: null,
      isReversingManualPay: 0
    }
  },
  computed: {
    isPaymentsOnboarded() {
      return (
        this.$store.state.session.company &&
        this.$store.state.session.company.counterparty_status &&
        (this.$store.state.session.company.counterparty_status === 'a' ||
          this.$store.state.session.company.counterparty_status === '#')
      )
    }
  },
  methods: {
    amountUpdated(fee) {
      this.invoice_service_fee_net = fee
    },
    async onPayNow() {
      if (this.isReversingManualPay === 1) return
      this.isReversingManualPay = 1

      try {
        await this.reversePayManually({
          invoice_id: this.invoice_id,
          is_contractor: false
        })
        this.reload()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Something went wrong. Please try again.',
          error: true
        })
      }

      this.isReversingManualPay = 0
    },
    reset() {
      this.quote.quote_unpaid_gross = this.quote.quote_unpaid_gross - this.invoice_gross
      this.quote.quote_pending_gross = this.invoice_gross
      const container = this.$el.querySelector('#payment-confirmation')
      setTimeout(() => {
        if (container) c.scrollTo(container)
        this.renderDoc()
      }, 1000)
    },
    onPaid() {
      this.invoice_status = 'p'
      // this.invoice_service_fee_net = this.serviceFee;
      this.reset()
    },
    onManualPay() {
      this.reload()
    },
    downloadInvoice() {
      this.savePDF()
    }
  },
  async mounted() {
    this.invoice = this.value[0]
    // setup for itemized items
    this.setItemizedItems()
  }
}
</script>
