<template>
  <Card class="text-center items-center">
    <template #title>
      {{ title }}
    </template>
    <Btn @click="sendPaymentsRequest" class="info round mx-auto mt-2"> {{ action }} </Btn>
  </Card>
</template>
<script>
export default {
  name: 'InvoicePaymentPrompt',
  props: {
    title: {
      type: String,
      default: 'Did you know you can pay through Bolster?'
    },
    action: {
      type: String,
      default: 'Request to pay instantly'
    },
    invoiceId: {
      type: String
    }
  },
  methods: {
    async sendPaymentsRequest() {
      await this.$store.dispatch('ajax', {
        path: '/invoice/requestPayments',
        data: {
          id: this.invoiceId
        }
      })

      this.$store.dispatch('alert', {
        message: 'Notification for payments has been sent'
      })
    }
  }
}
</script>
