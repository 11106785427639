<template>
  <card id="payment-confirmation" class="px-3">
    <div v-if="status === '$'" class="flex text-center w-full justify-center flex-col py-4">
      <h4>Thank You</h4>
      <p>Manual payment has been requested and is pending review</p>
      <br />
      <btn @click="payNow" :loading="loading" :disabled="loading === 1" class="info">
        Pay now, instantly and securely
      </btn>
    </div>
    <div v-else class="flex text-center w-100 justify-center flex-col py-4">
      <h4>Thank You</h4>
      <p>Your payment is currently being processed.</p>
      <Checkmark class="success my-2 mx-auto" :size="100" />
      <p>
        You will receive a confirmation email when your <br />
        payment has been completed.
      </p>
      <small class="mt-4">
        If payment is not processed successfully, an email will be sent to you with the
        corresponding reason.
      </small>
    </div>
  </card>
</template>
<script>
export default {
  name: 'InvoicePresentationSuccess',
  props: {
    status: {
      Type: String
    },
    payNow: {
      type: Function
    },
    loading: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
.success {
  .checkmark__circle {
    fill: $matcha-500 !important;
    stroke: $matcha-500 !important;
  }
}
</style>
