<template>
  <div id="payment-request-button">
    <!-- A Stripe Element will be inserted here. -->
  </div>
</template>
<script>
import StripePaymentsMixin from './StripePaymentsMixin'
import PayfacMixin from '../PayfacMixin'

export default {
  data() {
    return {}
  },
  props: {
    amount: {
      type: Number
    },
    name: {
      type: String
    }
  },
  methods: {},
  mixins: [PayfacMixin, StripePaymentsMixin],
  async mounted() {
    await this.createElements(false, true)
    await this.createPaymentRequestElement({
      currency: this.$store.state.session.company.currency_iso,
      country: this.$store.state.session.company.country_abbr,
      amount: this.amount,
      label: this.name
    })
  }
}
</script>
<style rel="stylesheet/scss" lang="scss"></style>
