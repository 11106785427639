<template>
  <div>
    <card-section>
      <card v-if="status === 'h'">
        <template #icon>
          <font-awesome-icon icon="trash" />
        </template>
        Invoice has been deleted
      </card>
      <card v-if="status === 'e'">
        <template #icon>
          <font-awesome-icon icon="credit-card" />
        </template>
        Invoice has been paid
        <small>
          {{ $f.timerelative(paidOn) }}
        </small>
      </card>
      <card v-if="status === '$'" class="px-3">
        <span class="flex text-center w-100 justify-center">
          Manual payment has been requested and is pending review
        </span>
        <btn @click="payNow" :loading="loading" :disabled="loading === 1" class="info my-2 w-100">
          Pay now, instantly and securely
        </btn>
      </card>
    </card-section>
  </div>
</template>
<script>
export default {
  name: 'InvoicePresentationStatus',
  props: {
    status: {
      Type: String
    },
    paidOn: {
      Type: Number
    },
    payNow: {
      type: Function
    },
    loading: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss"></style>
